.features__section {
    margin-bottom: 10rem;
    margin-top: 10rem;
}

.features__section__number {
    font-size: 2.5rem;
    font-weight: 400;
    color: var(--main-blue-dark);
}

.features__section__caption {
    max-width: 850px;
    margin: 0 auto;
    margin-bottom: 5rem;
    font-size: 1.7rem;
    color: var(--main-blue-dark);
}

.features__section__image {
    width: 100%;
}

@media screen and (max-width: 550px) {
    .features__section {
        margin-top: 3rem;
        margin-bottom: 5rem;
    }
    .features__section__caption {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }
}
