.loader {
    /* margin: 0 auto; */
    border: 4px solid #d3cece;
    border-radius: 50%;
    border-top: 4px solid #1979c8;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

.loader--large {
    width: 50px;
    height: 50px;
    border: 5px solid #d3cece;
    border-top: 5px solid #1979c8;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
