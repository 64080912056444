.intro {
    text-align: center;
    font-family: "Sanchez", serif;
    margin: 16rem 1rem 16rem 1rem;
}

.intro__title {
    color: var(--main-blue);
    font-style: italic;
    font-size: 3.5rem;
    font-weight: 200;
}

.intro__description {
    font-size: 1.5rem;
    max-width: 65rem;
    /* letter-spacing: 1px; */
    margin-bottom: 2rem;
}

.intro__caption {
    font-size: 1rem;
}

.intro__caption--greyed {
    font-size: 1rem;
    color: #7b7979;
    /* margin-top: 0px; */
    margin-bottom: 10px;
}

.intro__input {
    text-align: center;
    font-size: 1rem;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid black;
    font-family: Sanchez, serif;
    background-color: #efe6e6;
    margin-bottom: 1rem;
    border-radius: 2px;
}

.intro__input:focus::placeholder {
    color: transparent;
}

.intro__input_file {
    display: none;
}

.intro__button {
    font-family: "Sanchez", serif;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--main-blue-dark);
    color: white;
    border: 0;
    cursor: pointer;
}

.intro__button:hover {
    background-color: var(--main-blue);
}

.intro__button--disabled {
    background-color: #738a9d;
}

.intro__button--disabled:hover {
    background-color: #738a9d !important;
}

.mc {
    margin: 0 auto;
}

.mr1 {
    margin-right: 1rem;
}

.notification__success {
    color: var(--main-green);
}

.notification__link {
    color: var(--main-blue);
    text-decoration: none;
    cursor: pointer;
}

.notification__link:hover {
    text-decoration: underline;
}

.notification {
    font-size: 1.3rem;
}

.loader__caption {
    font-size: 1rem;
    color: #1979c8;
    margin-bottom: 1rem;
}

.loader__container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.error {
    color: red;
    font-family: courier;
}

@media screen and (max-width: 550px) {
    .intro {
        margin: 2rem 1rem 2rem 1rem;
    }

    .intro__title {
        font-size: 2rem;
    }
    .intro__description {
        font-size: 1rem;
    }
    .intro__button {
        font-size: 0.9rem;
    }
}
