.link {
    margin-bottom: 10rem;
}

.link__a {
    color: var(--main-blue);
    font-size: 1.5rem;
    text-decoration: none;
}

.link__a:hover {
    /* color: var(--main-blue-dark); */
    text-decoration: underline;
}

@media screen and (max-width: 550px) {
    .link {
        margin-bottom: 2rem;
    }
    .link__a {
        font-size: 1.2rem;
    }
}
