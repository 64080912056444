.features__title {
    color: var(--main-blue);
    font-size: 3rem;
    font-weight: 200;
}

@media screen and (max-width: 550px) {
    .features {
        margin: 0 2px 0 2px;
    }
    .features__title {
        font-size: 2rem;
    }
}
