.footer {
    display: flex;
    justify-content: space-between;
    text-align: start;
    margin: 0 5rem;
}

.footer__label {
    font-size: 1rem;
    color: #000;
    font-weight: 400;
    margin-bottom: 0;
}

.footer__value {
    color: var(--main-blue-dark);
    margin-top: 0;
    font-size: 0.9rem;
}

.value--link {
    text-decoration: none;
}

.footer__item {
    margin-bottom: 2rem;
}

.footer-contact {
    margin-top: 1rem;
}

@media screen and (max-width:550px) {
    .footer {
        margin: 0 1rem;
    }
}